import 'lazysizes';
// import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

// GLOBAL FUNCTIONS

const html = document.documentElement;
const body = document.body;

// ONE LINE FUNCTIONS

// Retourne une valeur entre un min et un max (compris dedans)
const randomRange = ( min, max ) => Math.floor( Math.random() * ( max - min + 1 ) + min );

// Est-ce que l'élément contient cette class
const contain = ( e, slug ) => e.classList.contains( slug );

// Pour supprimer un élément HTML
const removeNode = e => e.parentNode.removeChild( e );

// Prend tout le texte d'une string après la dernière occurence d'un caractère
const allBefore = ( e, str ) => e.substr( 0, e.lastIndexOf( str ) );
const allAfter = ( e, str ) => e.substr( e.lastIndexOf( str ) + str.length );


// FONCTION QUI MODIFIE L'URL DE LA PAGE
function modifyState( url ) {
    let stateObj = { id: "100" };
    window.history.replaceState( stateObj, "", url );
}

// INTERSECTION OBSERVER & FALLBACK
const figureIntersectionObserver = new IntersectionObserver(

    allObserved => {

        allObserved.forEach( observed => {

            if ( !observed.isIntersecting ) return;

            const img = observed.target;

            img.src = img.dataset.src;
            delete img.dataset.src;
            delete img.dataset.oc;
            img.classList.remove( "lazy" );

            figureIntersectionObserver.unobserve( img );

        } );

    }

);

// BROWSER DETECT QUI RETOURNE SAFARI NOT CHROME
// const browserDetect = () => {

//     var userAgent = navigator.userAgent;

//     return userAgent.match(/safari/i) && !userAgent.match(/chrome|chromium|crios/i)

// }

const marqueeClear = () => {

    const marquees = html.querySelectorAll( "body > header > .marquee" );
    if ( marquees ) {

        for( let i = 0; i < marquees.length; i++ ) {

            const e = marquees[ i ];
            if ( !contain( e, "marquee--loading" ) ) e.classList.add( "marquee--loading" );

        }

    }
    
}

const marqueeInit = () => {

    const marquees = html.querySelectorAll( "body > header .marquee" );
    if ( marquees ) {

        for( let i = 0; i < marquees.length; i++ ) {

            const e = marquees[ i ];
    
            const sentences = e.querySelectorAll( "p" );
            if ( sentences ) {
            
                setTimeout( () => {

                    var sentences_length = sentences.length;

                    const first_sentence = sentences[ 0 ];
                    // const sentenceWidth = first_sentence.offsetWidth;
                    const sentenceWidth = first_sentence.clientWidth;
                    
                    html.style.setProperty( `--marquee-${ i }-width`, `${ sentenceWidth }px` );

                    for ( let j = sentences_length / 2 - 1; j < sentences_length; j++ ) {

                        if ( sentenceWidth * ( j + 1 ) < window.innerWidth ) {
                            
                            const clone = first_sentence.cloneNode( true );
                            e.appendChild( clone );
                            sentences_length = sentences_length + 1;

                        }
                        
                    }

                    e.classList.remove( "marquee--loading" );

                }, 1000 );
            
            }

        }

    }
    
}

const closeNavs = () => {

    !html.classList.contains( "top" ) && html.classList.add( "top" );
    html.classList.contains( "nav-open" ) && html.classList.remove( "nav-open" );
    html.classList.contains( "filter-open" ) && html.classList.remove( "filter-open" );
    html.classList.contains( "clock-open" ) && html.classList.remove( "clock-open" );
    html.classList.contains( "upcoming-open" ) && html.classList.remove( "upcoming-open" );

}

const removeContainer = () => {

    const containers = html.querySelectorAll( ".container" );
    if ( containers.length > 4 ) {
        const lastContainer = containers[ containers.length - 1 ];
        const lastSlug = lastContainer.dataset.slug;
        removeNode( lastContainer );
        delete AJAX_data[ lastSlug ];
        delete slider_data[ lastSlug ];
    }

}

var AJAX_data = {};
var slider_data = {};

const AJAX_memoized = ( slug, targetHTML ) => {

    slug = slug === "ojai" ? "home" : slug;

    AJAX_data = AJAX_data || {};

    if ( AJAX_data[ slug ] ) {
    	return AJAX_data[ slug ];
    }
    
    return AJAX_data[ slug ] = targetHTML;

}

const AJAX = async ( e = null, target ) => {

    if ( e ) e.preventDefault();

    closeNavs();

    var href = target.href;
    var jump = null;
    var anchor = null;

    if ( href.includes( "_____" ) ) {
        jump = allAfter( href, "_____" );
        href = allBefore( href, "_____" );
    }

    if ( href.includes( "#" ) ) {
        anchor = allAfter( href, "#" );
        href = allBefore( href, "#" );
    }

    const slug = allAfter( href, "/" );
    const pageContainer = html.querySelector( `.container[data-slug="${ slug }"]` );
    const firstContainer = html.querySelector( ".container" );

    if ( pageContainer ) {

        if ( firstContainer !== pageContainer ) {

            firstContainer.matches( ".reports" ) && closeReport( firstContainer.querySelector( "main + aside > .swiper" ) )

            pageContainer.parentNode.insertBefore( pageContainer, firstContainer );

            if ( anchor ) {

                const article = pageContainer.querySelector( `#${ anchor }` );

                if ( article ) {

                    // article.scrollIntoView();
                    const main = pageContainer.querySelector( "main" );
                    main && scrollingTo( article, main )
    
                    if ( href.includes( "annual-reports" ) ) {

                        const reading_link = article.querySelector( ".read__link" );
                            reading_link && reading_link.classList.add( "reading" );
                        
                        AJAX_report( null, reading_link );

                    }
                    
                }

            }

            if ( jump ) {

                jump = jump.split( '-' );

                const memoized_slider = slider_data[ slug ][ Number( jump[ 0 ] ) ];

                memoized_slider && memoized_slider.slideTo( jump[ 1 ], 0 );

            }

            let request = new XMLHttpRequest();
                request.open( "GET", `${ href }`, true );
                request.onload = () => {
        
                    const targetHtml = AJAX_data[ slug ] || new DOMParser().parseFromString( request.responseText, 'text/html' );
    
                    const targetMenu = targetHtml.querySelector( "nav ul" );
                    const actualNav = html.querySelector( "nav" );
                    const actualMenu = actualNav.querySelector( "ul" );
    
                    if ( targetMenu !== actualMenu ) {
                        actualNav.replaceChild( targetMenu, actualMenu );
                    }
        
                }
                request.send();

            modifyState( href );
            
        }
        
    }

    if ( !pageContainer ) {

        firstContainer.matches( ".reports" ) && closeReport( firstContainer.querySelector( "main + aside > .swiper" ) )

        let request = new XMLHttpRequest();
            request.open( "GET", `${ href }`, true );
            request.onload = () => {
    
                const targetHtml = AJAX_data[ slug ] || new DOMParser().parseFromString( request.responseText, 'text/html' );
    
                const targetContainer = targetHtml.querySelector( ".container" );
                const actualContainer = html.querySelector( ".container" );
                const actualParent = actualContainer.parentNode;
                
                if ( targetContainer.innerHTML ) {
                    const targetClone = targetContainer.cloneNode( true );
                    actualParent.insertBefore( targetClone, actualContainer );

                    removeContainer();

                    // anchor && targetClone.querySelector( `#${ anchor }` ).scrollIntoView();

                    if ( anchor ) {

                        const article = targetClone.querySelector( `#${ anchor }` );

                        if ( article ) {
        
                            // article.scrollIntoView();
                            const main = targetClone.querySelector( "main" );
                            main && scrollingTo( article, main )
    
                            if ( href.includes( "annual-reports" ) ) {
        
                                const reading_link = article.querySelector( ".read__link" );
                                    reading_link && reading_link.classList.add( "reading" );

                                AJAX_report( null, reading_link );
        
                            }
                            
                        }
        
                    }

                    setTimeout( () => { pageInit( slug, jump ) }, 1000 );
                }

                const targetMenu = targetHtml.querySelector( "nav ul" );
                const actualMenu = html.querySelector( "nav ul" );

                targetMenu !== actualMenu && actualMenu.parentNode.replaceChild( targetMenu, actualMenu );
    
                AJAX_memoized( slug, targetHtml );
                modifyState( href );
    
            }
            request.send();
        
    }

}

const AJAX_filter = async ( target, action = "go" ) => {

    closeNavs();

    var url = html.querySelector( "header > a" ).href;
    var href = url;

    if ( action === "go" ) {
    
        var filter = target.dataset.filter
        var filterTag = filter.split( '' ).map( char => ( char === ' ' ? '%20' : char ) ).join( '' );
            href = `${ url }?tag=${ filterTag }`;

    }

    let request = new XMLHttpRequest();
        request.open( "GET", `${ href }`, true );
        request.onload = () => {

            const slug = action === "go" ? `tag_${ filterTag }` : "home";
    
            const targetHtml = AJAX_data[ slug ] || new DOMParser().parseFromString( request.responseText, 'text/html' );
            const targetContainer = targetHtml.querySelector( ".container" );

            const homeContainer = html.querySelector( `.container.home` );

            if ( homeContainer ) {
        
                const firstContainer = html.querySelector( ".container" );

                firstContainer !== homeContainer && homeContainer.parentNode.insertBefore( homeContainer, firstContainer );
    
                const targetGrid = targetContainer.querySelector( ".grid" );
                const homeGrid = homeContainer.querySelector( `.grid` );
                
                if ( targetGrid.innerHTML ) {
                    const targetClone = targetGrid.cloneNode( true );
                    homeGrid.parentNode.replaceChild( targetClone, homeGrid );
                }
                
            }

            if ( !homeContainer ) {
    
                const actualContainer = html.querySelector( ".container" );
                const actualParent = actualContainer.parentNode;
                
                if ( targetContainer.innerHTML ) {
                    const targetClone = targetContainer.cloneNode( true );
                    actualParent.insertBefore( targetClone, actualContainer );

                    const containers = html.querySelectorAll( ".container" );
                    containers.length > 4 && removeNode( containers[ containers.length - 1 ] )

                    setTimeout( () => { pageInit( slug ) }, 1000 );
                }

            }
    
            AJAX_memoized( slug, targetHtml );

            if ( action === "go" ) {
        
                if ( target.parentNode.parentNode.id === "filter__list" ) {
                    const currentActive = html.querySelector( `#filter__list .filter__btn--select.active` );
                    if ( currentActive ) {
                        currentActive !== target && currentActive.classList.remove( "active" );
                    }
                    target.classList.add( "active" );
                }
                if ( target.parentNode.parentNode.id !== "filter__list" ) {
                    console.log( `#filter__list .filter__btn--select[data-filter="${ filter }"]` )
                    html.querySelector( `#filter__list .filter__btn--select[data-filter="${ filter }"]` ).classList.add( "active" );
                }
                
            }
            if ( action !== "go" ) {
                target.parentNode.querySelector( ".active" ).classList.remove( "active" );
            }

            modifyState( url );

        }
        request.send();

}

const AJAX_report = async ( e, target ) => {

    if ( e ) e.preventDefault();

    const href = target.href;
    const aside = html.querySelector( `.container.reports aside` );
    if ( aside ) {

        closeReport( aside.querySelector( ".swiper" ) );

        const reading_link = html.querySelector( ".read__link.reading" );
            reading_link && reading_link.classList.remove( "reading" );
        
        target.classList.add( "reading" );

        let request = new XMLHttpRequest();
            request.open( "GET", `${ href }`, true );
            request.onload = () => {
    
                const targetHtml = new DOMParser().parseFromString( request.responseText, 'text/html' );
    
                const swiper = targetHtml.querySelector( ".swiper" );
                if ( swiper && swiper.innerHTML ) {
                    const targetClone = swiper.cloneNode( true );
                        aside.appendChild( targetClone );
                        initReportSliders( targetClone );
                }
            }
            request.send();
    }
}

// // COOKIE FUNCTIONS
// function setCookie( name, value, options = {} ) {

//     options = {
//         path: '/',
//         SameSite: 'Lax',
//         ...options
//     };

//     if (options.expires instanceof Date) {
//         options.expires = options.expires.toUTCString();
//     };

//     value = typeof value === "object" ? JSON.stringify( value ) : encodeURIComponent( value );

//     let updatedCookie = encodeURIComponent( name ) + "=" + value;

//     for (let optionKey in options) {
//         updatedCookie += "; " + optionKey;
//         let optionValue = options[optionKey];
//         if (optionValue !== true) {
//             updatedCookie += "=" + optionValue;
//         }
//     }

//     document.cookie = updatedCookie;
// }

// const getCookie = name => {

//     let matches = document.cookie.match( new RegExp(
//         "(?:^|; )" + name.replace( /([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1' ) + "=([^;]*)"
//     ) );

//     return matches ? decodeURIComponent( matches[ 1 ] ) : undefined;

// }

const clickInit = () => {

    const upcomingAside = html.querySelector( ".upcoming__aside" );
    const clockAside = html.querySelector( ".clock__aside" );

    html.addEventListener( "click", e => {

        var target = e.target;

        if ( target.matches( 'a:not([download]):not([target="_blank"]):not(.read__link)' ) ) {
            if ( !target.matches( 'a' ) ) { target = target.closest( 'a' ); }
            AJAX( e, target );
        }

        if ( target.matches( 'a.read__link' ) ) {
            if ( !target.matches( 'a' ) ) { target = target.closest( 'a' ); }
            AJAX_report( e, target );
            const article = target.closest( "article" );
            console.log(article);
            const main = target.closest( "main" );
            console.log(main);
            if ( article && main ) { scrollingTo( article, main ); }
        }

        if ( target.matches( 'header.landing *' ) || target.matches( 'header.landing' ) ) {
            if ( !target.matches( 'header.landing' ) ) target = target.closest( 'header.landing' );
            target.classList.remove( "landing" );
            setTimeout( () => { target.classList.remove( "no-hov" ); }, 1000 );
        }

        if (
            target.matches( '.marquee-1 *' )
            || target.matches( '.marquee-1' )
            || target.matches( '.clock__aside--close *' )
            || target.matches( '.clock__aside--close' )
        ) {
            if( clockAside ) html.classList.toggle( "clock-open" );
            if ( upcomingAside && contain( html, "upcoming-open" ) ) {
                setTimeout( () => { html.classList.remove( "upcoming-open" ); }, 1000 );
            }
        }

        if (
            target.matches( '.marquee-2 *' )
            || target.matches( '.marquee-2' )
            || target.matches( '.upcoming__aside--close *' )
            || target.matches( '.upcoming__aside--close' )
            || target.matches( '.nav__upcoming' )
        ) {
            upcomingAside && html.classList.toggle( "upcoming-open" );
            if ( clockAside && contain( html, "clock-open" ) ) {
                setTimeout( () => { html.classList.remove( "clock-open" ); }, 1000 );
            }
        }

        // if ( target.matches( ".cookie__btn" ) ) {
        //     const cookie_box = target.closest( ".cookie__box" );
        //     cookie_box && removeNode( cookie_box );
        //     target.matches( ".cookie__btn--decline" ) && alert( "You have no right to decline." );
        //     target.matches( ".cookie__btn--accept" ) && setCookie( "cookie", true );
        // }

        if ( target.matches( ".nav__btn--menu" ) ) {
            html.classList.toggle( "nav-open" );
            html.matches( ".filter-open" ) && html.classList.remove( "filter-open" );
        }
        if ( target.matches( "#filter__btn" ) ) {
            html.classList.toggle( "filter-open" );
            html.matches( ".nav-open" ) && html.classList.remove( "nav-open" );
        }

        if ( target.matches(".nav__btn--top")) {
            const container = html.querySelector(".container");
            if (container) {
                const main = container.querySelector("main");
                if (main) {
                    const topTarget = container.matches( ".home" ) ? "menu + *": "header + *";
                    const topElement = main.querySelector( topTarget );
                    topElement && scrollingTo( topElement, main, true );
                }
            }
        }

        if ( target.matches( "#unzoom__btn" ) ) {
            var gridWidth = getComputedStyle( html ).getPropertyValue( '--grid-width' );
            if (gridWidth) {
                gridWidth < 17 && setZoom( Number( gridWidth ) + 2 );
            }
        }
        if ( target.matches( "#zoom__btn" ) ) {
            var gridWidth = getComputedStyle( html ).getPropertyValue( '--grid-width' );
            if (gridWidth) {
                gridWidth > 5 && setZoom( Number( gridWidth ) - 2 );
            }
        }
        
        target.matches( "#deploy__btn" ) && deployGrid( true );
        target.matches( "#reset__btn" ) && deployGrid( false );

        target.matches( ".filter__btn--select" ) && AJAX_filter( target );
        target.matches( ".filter__btn--remove" ) && AJAX_filter( target, "back" );

        target.matches( ".swiper-button-close" ) && closeReport( target.closest( ".swiper" ), html.querySelector( ".read__link.reading" ) );

    } );

};

const scrollingTo = ( e, container, top = false ) => {

    const style = window.getComputedStyle( html, null).getPropertyValue( 'font-size' );
    const fontSize = parseFloat( style );
    const offset = fontSize * 4.95;
    
    // const containerRect = container.getBoundingClientRect().top;
    // const eRect = e.getBoundingClientRect().top;
    // const ePosition = eRect - containerRect;
    const ePosition = e.offsetTop;
    const offsetPosition = ePosition - offset.toFixed(2);

    setTimeout( () => {
        html.classList.contains( "top" ) && !top && html.classList.remove( "top" );
        !html.classList.contains( "top" ) && top && html.classList.add( "top" );

        container.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }, 100 );

}

const scrollbarInit = () => {

    var scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";

    body.appendChild( scrollDiv );

    var sb = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    html.style.setProperty( '--sb', `${ sb }px` );

    body.removeChild( scrollDiv );

}

const scrollInit = () => {

    var scrollTop = 0;

    html.addEventListener( "wheel", e => {
        html.matches( ".nav-open" ) && html.classList.remove( "nav-open" );

        const main = e.target.closest("main");
        if (main) {
            scrollTop = main.scrollTop;
            scrollTop < 100 && !html.matches(".top") && html.classList.add("top");
            scrollTop > 100 && html.matches(".top") && html.classList.remove("top");
        }
    } );

}

const clockDelay = ( e, calc ) => {
    console.log(e);
    e.style.animationDelay = `-${ calc }s`;
}

const clocksInit = () => {

    const times = html.querySelectorAll( ".time" );

    for (let i = 0; i < times.length; i++) {
        const time = times[ i ];
        var h = 0;
        var m = 0;
        var s = 0;

        const time_s = time.querySelector( ".time-s" );
        if( time_s ) {
            s = Number(time_s.dateTime);
            clockDelay( time_s, s );
        }
    
        // const delay_m = m*60+s-29.5;
        const time_m = time.querySelector( ".time-m" );
        if( time_m ) {
            m = Number(time_m.dateTime);
            clockDelay( time_m, m*60+s-29.5 );
        }
    
        const time_h = time.querySelector( ".time-h" );
        if( time_h ) {
            h = Number(time_h.dateTime);
            clockDelay( time_h, h*3600+m*30+s-29.5 );
        }
            
    }

    setTimeout( () => {
       html.classList.remove( "no-counter" )
    }, 100 );

}

// Fonction callback pour les mobiles Safari
const setUnits = () => {

    let height = window.innerHeight;
    let width = window.innerWidth;
    let ratio = width / height;

    if ( ratio > 1.8 && !contain( html, "wide" ) ) {
        html.classList.add( "wide" );
    }

    if ( ratio < 1.8 && contain( html, "wide" ) ) {
        html.classList.remove( "wide" );
    }

    let vh = height * 0.01;
    html.style.setProperty( '--vh', `${ vh.toFixed( 2 ) }px` );

};

const setZoom = val => {
    if ( !contain(html, "no-delay") ) { html.classList.add( "no-delay" ); }
    html.style.setProperty( '--grid-width', val );
    if ( contain(html, "no-delay") ) {
        setTimeout(() => {
            lazySizes.autoSizer.checkElems();
            html.classList.remove( "no-delay" );
        }, 600);
    }
}

const deployGrid = deploy => {
    const grid = html.querySelector( ".grid" );
    if ( grid ) {
        if ( !contain(html, "no-delay") ) { html.classList.add( "no-delay" ); }
        if ( contain(html, "no-delay") ) {
            const checkboxes = grid.querySelectorAll( "a > input[type='checkbox']" );
            for ( let i = 0; i < checkboxes.length; i++ ) {
                checkboxes[ i ].checked = deploy ? true : false;
            }
            setTimeout( () => {
                html.classList.remove( "no-delay" );
            }, 600);
        }
    }
}

const resizePanels = () => {

    if ( !contain( html, "no-delay" ) ) {
        html.classList.add( "no-delay" );
        marqueeClear();
    }

    clearTimeout( resizeTimer );

    var resizeTimer = setTimeout( () => {

        if ( contain( html, "no-delay" ) ) {
            setUnits();
            marqueeInit();
            html.classList.remove( "no-delay" );
        }

    }, 1000 );

}

const initResize = () => {

    setUnits();
    resizePanels();
    
}

const initSliders = ( slug, jump = null ) => {

    slider_data[ slug ] = {};

    if ( jump ) { jump = jump.split( '-' ); }

    const swipers = html.querySelectorAll( ".swiper" );
    for ( let i = 0; i < swipers.length; i++ ) {
        var swiper = new Swiper( swipers[ i ], {
            modules: [ Navigation, Pagination ],
            direction: 'horizontal',
            slidesPerView: "auto",
            centeredSlides: true,
            slideToClickedSlide: true,
            pagination: {
                el: ".swiper-pagination",
                type: "fraction",
            },
            navigation: {
                enabled: true,
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        } );
        
        slider_data = slider_data || {};
        slider_data[ slug ][ i ] = swiper;

        if ( jump && i === Number( jump[ 0 ] ) ) {
            swiper.slideTo( jump[ 1 ], 0 );
        }

    }

}

const closeReport = ( swiper = null, reading_link = null ) => {
    swiper && removeNode( swiper );
    reading_link && reading_link.classList.remove( "reading" );
}

const initReportSliders = target => {

    var slider = new Swiper( target, {
        modules: [ Navigation, Pagination ],
        direction: 'horizontal',
        height: 640,
        // slidesPerView: 2,
        // centeredSlides: true,
        slideToClickedSlide: true,
        pagination: {
            el: ".swiper-pagination",
            type: "fraction",
        },
        navigation: {
            enabled: true,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        slidesPerView: 2,
        slidesPerGroup: 2,
        centeredSlides: false,
        // slidesPerGroupSkip: 1,
        grabCursor: true,
        keyboard: {
            enabled: true,
        },
    } );

}

// GLOBAL INIT

const pageInit = ( slug, jump = null ) => {

    // const style = window.getComputedStyle( html, null).getPropertyValue( 'font-size' );
    // const fontSize = parseFloat( style );
    // const gap = fontSize*2;

    initSliders( slug, jump );

}

const init = () => {

    const container = html.querySelector( ".container" );

    clickInit();
    scrollInit();
    clocksInit();
    pageInit( container.dataset.slug );
    // if ( browserDetect ) console.log( "static" );

    setTimeout( () => {
        
        html.classList.remove( 'loading' );

    }, 200 );

    if ( window.attachEvent ) {

        window.attachEvent( 'onresize', () => initResize() );
        
    } else if ( window.addEventListener ) {

        window.addEventListener( 'resize', () => initResize(), true );
        
    }

}

const renderInit = () => {
    setUnits();
    scrollbarInit();
    marqueeInit();
}

document.addEventListener( 'DOMContentLoaded', init, false );

// document.fonts.onloadingdone = () => { renderInit() };

document.fonts.ready.then( () => { renderInit() } );

window.addEventListener( 'popstate', e => window.location = e.target );